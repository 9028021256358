import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router";
import { ErrorAlert, SuccessAlert } from "../../comman/sweetalert";
import Loader from "../../comman/loader";
import moment from "moment";

const Booking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [imagebuffer, setImageBuffer] = useState(null);
  const bookingInfo = JSON.parse(localStorage.getItem("bookingDetails"));

  const [myData, setMyData] = useState({
    full_name: "",
    email: "",
    number: "",
    reason: "",
    id_proof: "",
  });

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    number: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Mobile number is required"),
    reason: Yup.string().required("Reason is required"),
    // id_proof: Yup.mixed().required("ID proof is required"),
  });

  const submitPaymentInfo = async (values) => {
    setIsLoading(true);

    let formData = new FormData();
    formData.append("image", imagebuffer);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upload`,
        formData
      );
      setImage(res.result);

      if (res.data.code === 200) {
        localStorage.setItem(
          "values",
          JSON.stringify({ ...values, id_proof: res.data.result })
        );
        navigate("/payment");
      }
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImage = async (e) => {
    setImageBuffer(e.target.files[0]);
  };

  const checkInDateDiff = moment(bookingInfo.checkInDate);
  const checkOutDateDiff = moment(bookingInfo.checkOutDate);

  const diffInDays = checkOutDateDiff.diff(checkInDateDiff, "days");

  const location = useLocation();
  const data = location?.state?.data;
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container mx-auto comman-padding mt-[100px] sm:mt-[100px] md:mt-[150px]">
          <div className="flex items-center">
            <div className="comman-midium-grey">
              <p>Floor {data?.floor} /</p>
            </div>
            <div className="comman-midium-grey mx-2">
              <p>Unit {data.unit_no} /</p>
            </div>
            <div className="comman-blog-header mx-2">
              <p> Book Property</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 top">
            <div className="col-span-12 lg:col-span-6">
              <Formik
                initialValues={myData}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  submitPaymentInfo(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <div className="payment-info">
                    <div className="login-input">
                      <div className="comman-grey">
                        <p>Name</p>
                      </div>
                      <input
                        type="text"
                        className="input-box mt-1"
                        placeholder="Name"
                        value={values.full_name}
                        name="full_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="text-red-600">
                      {errors.full_name &&
                        touched.full_name &&
                        errors.full_name}
                    </div>

                    <div className="flex items-center mt-4">
                      <div className="login-input flex flex-col">
                        <div className="comman-grey">
                          <p>E-mail</p>
                        </div>
                        <input
                          type="email"
                          className="input-box mt-1"
                          placeholder="E-mail"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="text-red-600">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </div>

                      <div className="login-input ml-4 flex flex-col">
                        <div className="comman-grey">
                          <p>Phone Number</p>
                        </div>
                        <input
                          type="number"
                          className="input-box mt-1"
                          placeholder="Phone Number"
                          value={values.number}
                          name="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="text-red-600">
                          {errors.number && touched.number && errors.number}
                        </div>
                      </div>
                    </div>

                    <div className="login-input mt-4">
                      <div className="comman-grey">
                        <p>Reason to stay</p>
                      </div>
                      <textarea
                        type="text"
                        className="text-area mt-1"
                        value={values.reason}
                        name="reason"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Write here..."
                      />
                    </div>
                    <div className="text-red-600">
                      {errors.reason && touched.reason && errors.reason}
                    </div>

                    <div className="login-input mt-3">
                      <div className="comman-grey">
                        <p>Upload ID Proof</p>
                      </div>
                      <input
                        type="file"
                        className="input-box mt-1"
                        placeholder="Phone Number"
                        onChange={uploadImage}
                      />
                    </div>
                    {/* <div className="text-red-600">
                      {errors.id_proof && touched.id_proof && errors.id_proof}
                    </div> */}

                    <div className="mt-3">
                      <button
                        type="button"
                        class="text-white  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 
                      focus:outline-none comman-bg w-full"
                        onClick={handleSubmit}
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="payment-info">
                <div className="flex items-center">
                  <div className="box-payment">
                    <img
                      src={data?.images[0]}
                      alt=""
                      className="w-full object-cover h-full"
                    />
                  </div>
                  <div className="mx-3">
                    <p className="comman-blog-header">{data?.unitType?.name}</p>
                    <p>Unit No. {data?.unit_no}</p>
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-4 top">
                  <div className="col-span-4 lg:col-span-4 payment-checkIn">
                    <div className="comman-grey">
                      <p>Check-in</p>
                    </div>
                    <div className="comman-grey mt-2">
                      <p>
                        {moment(bookingInfo?.checkInDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-4 payment-checkIn">
                    <div className="comman-grey">
                      <p>Check-out</p>
                    </div>
                    <div className="comman-grey mt-2">
                      <p>
                        {moment(bookingInfo?.checkOutDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-4 payment-checkIn">
                    <div className="comman-grey">
                      <p>Stay</p>
                    </div>
                    <div className="comman-grey mt-2">
                      <p>{diffInDays} Days</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top payment-info">
                <div className="comman-blog-header">
                  <p>{data?.unitType?.name}</p>
                </div>
                <div className="flex justify-between items-center mt-3">
                  <div className="comman-grey">
                    <p>
                      ${data?.newPrice ? data?.newPrice : data?.price} *{" "}
                      {diffInDays} Days
                    </p>
                  </div>
                  <div className="comman-grey mt-2">
                    <p>
                      $
                      {data?.newPrice
                        ? data?.newPrice * diffInDays
                        : data?.price * diffInDays}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                  <div className="comman-grey">
                    <p>Clean and Dry</p>
                  </div>
                  <div className="comman-grey mt-2">
                    <p>${data?.cleaning_fees}</p>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                  <div className="comman-grey">
                    <p>Deposit</p>
                  </div>
                  <div className="comman-grey mt-2">
                    <p>${data?.deposit | 0}</p>
                  </div>
                </div>

                <div class="border-b border-solid border-grey-500 mt-3"></div>

                <div className="flex justify-between items-center mt-3">
                  <div className="comman-blue-font">
                    <p>Total</p>
                  </div>
                  <div className="comman-blue-font">
                    <p>
                      ${" "}
                      {(data?.newPrice
                        ? data?.newPrice * diffInDays
                        : data?.price * diffInDays) +
                        (data?.cleaning_fees || 0) +
                        (data?.deposit || 0)}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
