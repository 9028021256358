import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import FilterSearchCard from "./RoomsGrid";
import { useNavigate } from "react-router-dom";

const SearchFilter = () => {
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [checkinLease, setCheckinLease] = useState(null);
  const [guests, setGuests] = useState("");
  const [minbed, setMinbed] = useState("");
  const [mexbed, setMexbed] = useState("");
  const navigate = useNavigate();

  return (
    <div>
      <div className="search-and-features-area pb-50 rpb-20 rel z-1">
        <div className="container-1550">
          <div className="flex flex-wrap">
            <div className="md:w-1/2 pr-4 pl-4">
              <div className="search-filter-inner rel z-2">
                <div className="section-title text-white mb-20 wow fadeInUp delay-0-2s animated">
                  <b>
                    <h3>Leases</h3>
                  </b>
                  <span className="sub-title mb-5">
                    Please use this portal for monthly/yearly leasing.
                  </span>
                </div>
                <div className="flex flex-wrap items-center">
                  <div className="w-full lg:w-1/2 pr-4 pl-4">
                    <div className="form-group filter-item wow fadeInUp delay-0-3s customeUnit mt-[15px]">
                      <DatePicker
                        selected={checkinLease}
                        onChange={(date) => setCheckinLease(date)}
                        placeholderText="Start Date"
                        minDate={new Date()}
                        className="form-control datepicker customeWidth"
                        popperPlacement="top"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 pr-4 pl-4">
                    <div className="w-full filter-item wow fadeInUp delay-0-5s customeUnit">
                      <select
                        name="adults"
                        id="adults"
                        style={{ position: "relative", zIndex: 1 }}
                        value={minbed}
                        onChange={(e) => setMinbed(e.target.value)}
                      >
                        <option value="">Minimum Bed(s)</option>
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                      </select>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 pr-4 pl-4">
                    <div className="filter-item wow fadeInUp delay-0-5s customeUnit ">
                      <select
                        name="adults"
                        id="adults"
                        value={mexbed}
                        onChange={(e) => setMexbed(e.target.value)}
                      >
                        <option value="">Lease Term</option>
                        <option value="1">3-5 months(10% premium)</option>
                        <option value="2">6-11 months(5% premium)</option>
                        <option value="3">12+ months(no premium)</option>
                      </select>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 pr-4 pl-4 customeUnit">
                    <button
                      className="theme-btn style-two wow fadeInUp delay-0-7s"
                      disabled={!checkinLease || !mexbed || !minbed}
                      onClick={() => {
                        localStorage.setItem(
                          "leaseInfo",
                          JSON.stringify({
                            checkinLease: checkinLease,
                            minbed: minbed,
                            mexbed: mexbed,
                          })
                        );
                        localStorage.setItem("isLeaseBook", "true");
                        navigate("/property");
                      }}
                    >
                      Search
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 pr-4 pl-4">
              <div
                className="search-filter-inner rel z-2"
                style={{ padding: "50px 80px 40px" }}
              >
                <div className="section-title text-white mb-20 wow fadeInUp delay-0-2s">
                  <h3>Bookings</h3>
                  <span className="sub-title mb-5">
                    Please use this portal for daily/weekly bookings.
                  </span>
                </div>
                <div className="flex flex-wrap ">
                  <div className="md:w-1/2 pr-4 pl-4">
                    <div className="form-group mb-4 filter-item wow fadeInUp delay-0-3s customeUnit">
                      <DatePicker
                        selected={checkInDate}
                        onChange={(date) => setCheckInDate(date)}
                        placeholderText="Check In"
                        minDate={new Date()}
                        popperPlacement="top"
                        className="form-control datepicker customeWidth"
                      />
                    </div>
                  </div>
                  <div className="md:w-1/2 pr-4 pl-4">
                    <div className="mb-4 filter-item wow fadeInUp delay-0-3s customeUnit">
                      <DatePicker
                        selected={checkOutDate}
                        onChange={(date) => setCheckOutDate(date)}
                        placeholderText="Check Out"
                        popperPlacement="top"
                        minDate={checkInDate || new Date()}
                        className="form-control datepicker customeWidth"
                      />
                    </div>
                  </div>
                  <div className="md:w-1/2 pr-4 pl-4">
                    <div className="filter-item wow fadeInUp delay-0-5s customeUnit">
                      <select
                        name="adults"
                        id="adults"
                        value={guests}
                        onChange={(e) => setGuests(e.target.value)}
                      >
                        <option value="">Guests</option>
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                        <option value="4"> 4</option>
                        <option value="5"> 5</option>
                        <option value="6"> 6</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:w-1/2 pr-4 pl-4 customeUnit">
                    <button
                      onClick={() => {
                        localStorage.setItem(
                          "dateInfo",
                          JSON.stringify({
                            checkInDate: checkInDate,
                            checkOutDate: checkOutDate,
                            guests: guests,
                          })
                        );
                        localStorage.setItem("isLeaseBook", "false");

                        navigate("/property");
                      }}
                      className="theme-btn style-two wow fadeInUp delay-0-7s"
                      style={{marginBottom:"5px"}}
                    >
                      Search
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines for-bg-white">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
