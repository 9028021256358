import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";

const InstagramArea = () => {
  return (
    <section className="instagram-area">
      <div className="instagram-item wow fadeInUp delay-0-2s">
        <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
          <NavLink
            to={"/assets/images/site/bathroom.png"}
            className="instagram-gallery"
          >
            <img
              src="newImages/images/site/bathroom.png"
              width={384}
              height={320}
              alt="instagram"
            />
          </NavLink>
        </ScrollAnimation>
      </div>
      <div className="instagram-item wow fadeInUp delay-0-3s">
        <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
          <NavLink
            className="instagram-gallery"
            to={"assets/images/site/bedroom.png"}
          >
            <img
              src="newImages/images/site/bedroom.png"
              width={384}
              height={320}
              alt="instagram"
            />
          </NavLink>
        </ScrollAnimation>
      </div>
      <div className="instagram-item wow fadeInUp delay-0-4s">
        <ScrollAnimation
          duration={3}
          animateIn="fadeIn"
          animateOnce={true}
          className="h-[250px]"
        >
          <div className="content text-white">
            <div className="icon">
              <NavLink
                to={
                  "https://www.instagram.com/loftsatjc?igsh=MWtiMHRrZDBmbDQ2eA=="
                }
                target="_black"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </NavLink>
            </div>
            <h2>
              <NavLink
                to={
                  "https://www.instagram.com/loftsatjc?igsh=MWtiMHRrZDBmbDQ2eA=="
                }
                target="_black"
              >
                Follow Our Instagram
              </NavLink>
            </h2>
            <hr style={{ backgroundColor: "white" }} />
            <div className="call-text">Make A Call</div>
            <NavLink className="h2" to={"callto:+1 (347) 915-3560"}>
              +1 (347) 915-3560
            </NavLink>
          </div>
        </ScrollAnimation>
      </div>
      <div className="instagram-item wow fadeInUp delay-0-5s">
        <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
          <a
            className="instagram-gallery"
            href="assets/images/site/kitchen.png"
          >
            <img
              src="newImages/images/site/kitchen.png"
              width={384}
              height={320}
              alt="instagram"
            />
          </a>
        </ScrollAnimation>
      </div>
      <div className="instagram-item wow fadeInUp delay-0-6s">
        <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
          <a className="instagram-gallery" href="assets/images/site/dining.png">
            <img
              src="newImages/images/site/dining.png"
              width={384}
              height={320}
              alt="instagram"
            />
          </a>
        </ScrollAnimation>
      </div>
    </section>
  );
};

export default InstagramArea;
