import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faBehance,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink } from "react-router-dom";

const FooterArea = () => {
  return (
    <footer className="main-footer bgc-black-with-lighting pt-50 rel z-1">
      <ScrollAnimation duration={4} animateIn="fadeIn" animateOnce={true}>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4">
            <div className="col-span-1">
              <div className="footer-widget widget_about wow fadeInUp delay-0-2s">
                <div className="footer-logo mb-25">
                  <NavLink to="/">
                    <img
                      src="newImages/images/site/logo.gif"
                      width={150}
                      style={{ marginTop: "-9%" }}
                      alt="Logo"
                    />
                  </NavLink>
                </div>
                <p>
                  Grand Monarch offers a selection of exclusive apartments that
                  set the standard for elegance and refinement. Our premium
                  collection includes beautifully designed one, two, and
                  three-bedroom units, each ergonomically designed to ensure
                  unparalleled luxury and comfort!
                </p>
                <div className="social-style-one pt-10">
                  <NavLink
                    to="https://www.facebook.com/people/LOFTS-at-JC/100095407963999/"
                    target="_black"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </NavLink>
                  <NavLink
                    to={
                      "https://www.instagram.com/loftsatjc?igsh=MWtiMHRrZDBmbDQ2eA=="
                    }
                    target="_black"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </NavLink>
                  {/* <NavLink to="/">
                    <FontAwesomeIcon icon={faTwitter} />
                  </NavLink>
                  <NavLink to="/">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </NavLink> */}
                  {/* <NavLink to="/">
                    <FontAwesomeIcon icon={faBehance} />
                  </NavLink> */}
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="footer-widget widget_nav_menu wow fadeInUp delay-0-4s">
                <h4 className="footer-title w-full flex justify-center">
                  Quick Links
                </h4>
                <ul className="list-style-one w-full flex items-start flex-col">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/about"}>About</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/gallery"}>Gallery</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/apartments"}>Appartments</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/amenities"}>Amenities</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/blog"}>Blog</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/floor-plan"}>Virtual Tour</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/contact"}>Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="">
              <div className="w-full  col-span-1">
                <h4 className="footer-title text-xl font-semibold mb-4 text-white">
                  Contact Us
                </h4>
                <div className="tab-pane fade active show" id="australia">
                  {/* Address */}
                  <div className="mb-40 flex items-start">
                    <div className="icon text-2xl mr-4 text-white ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="35px"
                        viewBox="0 -960 960 960"
                        width="35px"
                        fill="currentColor"
                      >
                        <path d="M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z" />
                      </svg>
                    </div>
                    <div className="content">
                      <span className="mb-2 text-white block">Address</span>
                      <p className="text-white">
                        <div> 128 Grand Avenue</div>{" "}
                        <div> Johnson City NY 13790</div>
                      </p>
                    </div>
                  </div>

                  {/* Email Address */}
                  <div className="mb-40 flex items-start">
                    <div className="icon text-2xl mr-4 text-white pt-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="35px"
                        viewBox="0 -960 960 960"
                        width="35px"
                        fill="currentColor"
                      >
                        <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                      </svg>
                    </div>
                    <div className="content">
                      <span className="mb-2 text-white block">
                        Email Address
                      </span>
                      <p>
                        <a
                          href="mailto:info@grand-monrach.com"
                          className="text-white"
                        >
                          info@grand-monrach.com
                        </a>
                      </p>
                    </div>
                  </div>

                  {/* Make A Call */}
                  <div className="mb-10 flex items-start">
                    <div className="icon text-2xl mr-4 text-white pt-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="35px"
                        viewBox="0 -960 960 960"
                        width="35px"
                        fill="currentColor"
                      >
                        <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                      </svg>
                    </div>
                    <div className="content">
                      <span className="mb-2 text-white block">Call/Text</span>
                      <p>
                        <a href="tel:+13479153560" className="text-white">
                          (347) 915-3560
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="footer-widget widget_newsletter wow fadeInUp delay-0-6s">
                <h4 className="footer-title">Find Us</h4>
                <br />
                <div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2959.874488692318!2d-75.9536936!3d42.1101588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89daeef969ab6ffb%3A0x11082d4814a2309e!2s128%20Grand%20Ave%2C%20Johnson%20City%2C%20NY%2013790%2C%20USA!5e0!3m2!1sen!2sin!4v1724435663272!5m2!1sen!2sin"
                    width="100%"
                    height="215"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bgd-dark  pt-20 pb-5 rpt-25">
          <div className="container">
            <div className="copyright-text">
              <center>
                <p>
                  © 2024 <a href="index.html">Grand Monarch.</a> All Rights
                  Reserved.
                </p>
              </center>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <div className="bg-lines">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="wave-shapes" />
      <div className="wave-shapes-two" />
    </footer>
  );
};

export default FooterArea;
