import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import { ErrorAlert } from "../../comman/sweetalert";
import axios from "axios";
import Loader from "../../comman/loader";

const TestimonialsArea = () => {
  const [testimonials, setTestimonials] = useState([
    {
      description:
        "I moved here for work and the lofts best fit my criteria. Beautifully furnished, safe, with great amenities! Management was also really wonderful and super easy to contact regarding anything. I would have kept living here had I not been relocated for work! Highly recommend this place!",
      full_name: "Verified Review",
    },
    {
      description:
        "Customer service is great. The entire experience was only a positive one. Parking is convenient. Amenities are great. Got only positive things to say about the place.",
      full_name: "Verified Review",
    },
    {
      description:
        "Great apartments great amenities and best customer service, we're on call whenever I needed anything and were extremely accommodating.",
      full_name: "Verified Review",
    },
    {
      description:
        "It is my second year living at LOFTS@JC. I would like to share my amazing experiences! This is a new building with premium appliances, furnishings and everything else. It is located in a quiet residential street, yet close to everything. Parking on the building lot is convenient. While LOFTS@JC is on the higher end in terms of price, the old adage “you get what you pay for” definitely applies here: pay a drop more and enjoy a luxurious, premium, top-of-the-notch living experience!",
      full_name: "Verified Review",
    },
    {
      description:
        "Loved the style with premium furnishing and so many amenities like the game room, lounge and their high quality gym! Great service- super responsive and friendly. Also the location was a big plus, it's a quiet street but close to everything. 100% 5-star experience!",
      full_name: "Verified Review",
    },
    {
      description:
        "I stayed in their 1 bedroom furnished apartment for 12 weeks. It was my first time traveling for a job and I was worried. I am so glad I chose this apartment. The gated compound and access key for entrance made me feel comfortable and safe. The management was awesome. They are always there when needed. I felt like I was at home and surrounded by family. Thank you all for making my stay enjoyable",
      full_name: "Verified Review",
    },
    {
      description:
        "I had a great stay here , the location was convenient to my hospital plus centrally located to everything in the area. But most importantly the team was accommodating, always willing and able to help at anytime and communicate was the best they responded within seconds and help came immediately. If you’re looking for a place to stay don’t hesitate look into lofts jV",
      full_name: "Verified Review",
    },
    {
      description:
        "Beautiful furnished apartments with great amenities. Excellent customer service. Very prompt response to any questions . Would highly recommend.",
      full_name: "Verified Review",
    },
    {
      description:
        "Property is properly managed and I have been glad to call it my second home these past two years. Perfect for student and young-professional living. Everything is kept clean and properly maintained and living here has been nothing short of excellent. John is a great guy to work with and Praveen maintains excellent communication. They'll have you taken care of without a shadow of a doubt!",
      full_name: "Verified Review",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 slides at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const getTestimonials = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/testimonials`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );
      // setTestimonials(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTestimonials();
  }, []);
  return (
    <section className="testimonials-area-three py-50 rpy-100 rel z-1 comman-padding">
      <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
        <div className="container mx-auto">
          <div className="flex justify-center">
            <div className="md:col-span-9 lg:col-span-7 xl:col-span-5">
              <div className="section-title text-center mb-60 rmb-40 wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">Our Testimonials</span>
                <h2>What Our Customer Say Us</h2>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="testimonials-three-slider">
              <Slider {...settings}>
                {testimonials &&
                  testimonials.map((item, index) => (
                    <div
                      key={index}
                      className="testimonials-item-three wow fadeInUp  delay-0-2s mr-20 ml-20 h-[450px]"
                    >
                      <div className="author">
                        <img
                          src={
                            item?.image
                              ? item?.image
                              : require("../../../assets/images/testimonial.jpg")
                          }
                          alt="Testi Author"
                          className="rounded-full w-[40%] h-[90px] object-cover"
                        />
                      </div>
                      <div
                        className="text"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 5,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.description}
                      </div>
                      <div className="testi-des">
                        <h6>
                          <h6>{item?.full_name}</h6>
                        </h6>
                        {/* <span>CEO &amp; Founder</span> */}
                      </div>
                      <div className="ratting">
                        {Array.from({ length: item?.rating }, (_, index) => (
                          <FontAwesomeIcon icon={faStar} />
                        ))}
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          )}
        </div>
      </ScrollAnimation>
      <div className="bg-lines for-bg-white">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
  );
};

export default TestimonialsArea;
