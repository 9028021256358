import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarAlt,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import Loader from "../../comman/loader";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import TruncateTextLimited from "../../comman/trancateLimited";

const BlogArea = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  const navigation = useNavigate();
  const getBlogs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/blogs`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setBlog(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBlogs();
  }, []);
  const Navigation = (id) => {
    navigation("/blog-Details", { state: { id: id } });
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="blog-area rpt-100 pb-100 rpb-70 rel z-1">
          <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
            <div className="container mx-auto">
              <div className="flex justify-center">
                <div className="w-full max-w-3xl">
                  <div className="section-title text-center mb-70 rmb-50 wow fadeInUp delay-0-2s">
                    <h2>Here’s the latest at Grand Monarch</h2>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                  {blog &&
                    blog?.slice(0, 3)?.map((item, index) => {
                      const imageUrl = item?.thumbnail[0];
                      return (
                        <>
                          <div
                            onClick={() => Navigation(item?.id)}
                            className="blog-grid-item wow fadeInUp delay-0-2s cursor-pointer"
                          >
                            <div className="image">
                              <img
                                src={
                                  imageUrl
                                    ? imageUrl
                                    : "newImages/images/site/about2.png"
                                }
                                alt="Blog"
                                className="h-[360px]"
                              />
                            </div>
                            <div className="blog-content">
                              <h4
                                onClick={() => Navigation(item?.id)}
                                className="cursor-pointer"
                              >
                                {item?.title}
                                {/* <NavLink
                                  to={{
                                    pathname: `/blog-Details`,
                                    state: { id: item?.id },
                                  }}
                                >
                                  
                                </NavLink> */}
                              </h4>
                              <ul className="blog-meta">
                                <li
                                  onClick={() => Navigation(item?.id)}
                                  className="cursor-pointer"
                                >
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="mr-2"
                                  />
                                  {item?.author}
                                  {/* <NavLink
                                    to={{
                                      pathname: `/blog-Details`,
                                      state: { id: item?.id },
                                    }}
                                  >
                                    
                                  </NavLink> */}
                                </li>
                                <li
                                  onClick={() => Navigation(item?.id)}
                                  className="cursor-pointer"
                                >
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    className="mr-2"
                                  />
                                  {moment(item?.date).format("DD MMMM, YYYY")}
                                  {/* <NavLink
                                    to={{
                                      pathname: `/blog-Details`,
                                      state: { id: item?.id },
                                    }}
                                  >
                                    {moment(item?.date).format("DD MMMM, YYYY")}
                                  </NavLink> */}
                                </li>
                              </ul>
                              <p>
                                <TruncateTextLimited
                                  text={item?.description}
                                  maxLength={200}
                                />
                                {/* {item?.description} */}
                              </p>
                              <span
                                onClick={() => Navigation(item?.id)}
                                className="cursor-pointer"
                              >
                                Read More{" "}
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  className="ml-2"
                                />
                              </span>
                              {/* <NavLink
                                to={{
                                  pathname: `/blog-Details`,
                                  state: { id: item?.id },
                                }}
                                className="read-more"
                              ></NavLink> */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <div class="bg-lines for-bg-white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogArea;
