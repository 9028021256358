import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import Loader from "../../comman/loader";
import NoData from "../../comman/nodata";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "./Modal";
import { NavLink } from "react-router-dom";

const animationVariants = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 100,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 1,
    },
  },
};

const GallaryList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [gallaryType, setGallaryType] = useState([]);
  const [gallaryData, setGallaryData] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [typeId, setTypeId] = useState(null);

  const handleItemClick = (index, id) => {
    setTypeId(id);
    setActiveIndex(index === activeIndex ? null : index); // Toggle active index
  };

  const handleImageClick = (e, image) => {
    const rect = e.target.getBoundingClientRect();
    setInitialState({
      x: rect.left,
      y: rect.top,
      width: rect.width,
      height: rect.height,
    });
    setSelectedImage(image);
  };

  const getGallaryType = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/gallery-type`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setGallaryType(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const getGallary = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/gallery?type=${
          typeId ? typeId : "All"
        }`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setGallaryData(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGallaryType();
    getGallary();
  }, []);

  useEffect(() => {
    getGallary();
  }, [typeId]);

  return (
    <>
      <div className="container mx-auto comman-padding">
        <div className="comman-header text-center mb-5 mt-[150px] sm:mt-[100px] md:mt-[176px]">
          <p>Gallery</p>
        </div>
        <div className="flex overflow-x-scroll items-center justify-center">
          <ul className="flex flex-nowrap overflow-x-auto text-sm font-medium text-center text-gray-500 dark:text-gray-400 mt-5">
            <li className="me-2">
              <NavLink
                className={`inline-block px-4 py-3 rounded-lg ${
                  activeIndex === null
                    ? "text-white comman-bg rounded-lg active"
                    : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor"
                }`}
                onClick={() => handleItemClick(null)} // Handle click event for "All"
              >
                All
              </NavLink>
              {/* <a
                className={`inline-block px-4 py-3 rounded-lg ${
                  activeIndex === null
                    ? "text-white comman-bg rounded-lg active"
                    : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor"
                }`}
                onClick={() => handleItemClick(null)} // Handle click event for "All"
              >
                All
              </a> */}
            </li>
            {gallaryType?.map((item, index) => (
              <li className="me-2 mb-4" key={index}>
                <NavLink
                  className={`inline-block px-4 py-3 rounded-lg ${
                    activeIndex === index
                      ? "text-white comman-bg rounded-lg active"
                      : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor"
                  }`}
                  onClick={() => handleItemClick(index, item.id)} // Handle click event
                >
                  {item.name}
                </NavLink>
                {/* <a
                  className={`inline-block px-4 py-3 rounded-lg ${
                    activeIndex === index
                      ? "text-white comman-bg rounded-lg active"
                      : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor"
                  }`}
                  onClick={() => handleItemClick(index, item.id)} // Handle click event
                >
                  {item.name}
                </a> */}
              </li>
            ))}
          </ul>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="grid grid-cols-12 gap-4 mt-5">
              {gallaryData.map((item, index) => (
                <motion.div
                  className="lg:col-span-3 col-span-6 image"
                  key={index}
                  variants={animationVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  onClick={(e) => handleImageClick(e, item.image)}
                >
                  <img
                    src={item.image}
                    alt="No Images load"
                    className="rounded-lg h-full w-full"
                    style={{ maxHeight: "200px" }}
                  />
                </motion.div>
              ))}
              {gallaryData.length === 0 && <NoData />}
            </div>
            <AnimatePresence>
              {selectedImage && (
                <Modal
                  selectedImage={selectedImage}
                  initialState={initialState}
                  setSelectedImage={setSelectedImage}
                />
              )}
            </AnimatePresence>
          </div>
        )}
      </div>
    </>
  );
};

export default GallaryList;
