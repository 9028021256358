import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed as fasBed, faBath as fasBath } from '@fortawesome/free-solid-svg-icons';

const FilterSearchCard = () => {
  const rooms = [
    {
      id: 1,
      image: 'newImages/images/site/0.jpg',
      title: '2 Bed / 2 Bath',
      bedroom: 2,
      bathroom: 2,
      description: 'This is the First flat on the first floor',
    },
    {
      id: 2,
      image: 'newImages/images/site/1 (1).jpg',
      title: '1 Bed / 1 Bath',
      bedroom: 1,
      bathroom: 1,
      description: 'This is the Eighth flat on the first floor',
    },
    {
      id: 3,
      image: 'newImages/images/site/2 (1).jpg',
      title: '1 Bed / 1 Bath',
      bedroom: 1,
      bathroom: 1,
      description: 'This is the Eighth flat on the first floor',
    },
    {
      id: 4,
      image: 'newImages/images/site/0.jpg',
      title: '2 Bed / 2 Bath',
      bedroom: 2,
      bathroom: 2,
      description: 'This is the First flat on the first floor',
    },
    {
      id: 5,
      image: 'newImages/images/site/1 (1).jpg',
      title: '1 Bed / 1 Bath',
      bedroom: 1,
      bathroom: 1,
      description: 'This is the Eighth flat on the first floor',
    },
  ];


  // const navigate = useNavigate();

  const handleNavigate = () => {
    // navigate('/property-details', { state: { id: id } });
  };

  return (
    <div>
      <section className="rooms-grid-area pb-125 rpb-95 rel z-2">
        <div className=" mx-auto sm:px-4">
          <div className="flex flex-wrap">
            {rooms && rooms?.map((unit, index) => (
              <div className="xl:w-1/3 pr-4 pl-4 md:w-1/2 pr-4 pl-4" key={index}>
                <div className="room-item style-two wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={unit?.image}
                      style={{ width: "100%", height: 270 }}
                      alt="Room"
                    />
                  </div>
                  <div className="content">
                    <h4>
                      <button onClick={() => handleNavigate(unit?.id)}>
                        {unit.title}
                      </button>
                    </h4>
                    <ul className="blog-meta w-full">
                      <li onClick={() => handleNavigate(unit?.id)}>
                        <FontAwesomeIcon icon={fasBed} className="mr-2" />
                        Bedroom : {unit?.bedroom}
                      </li>
                      <li onClick={() => handleNavigate(unit?.id)}>
                        <FontAwesomeIcon icon={fasBath} className="mr-2" />
                        Bathroom : {unit?.bathroom}
                      </li>
                    </ul>
                    <p className='w-full'>{unit.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-lines for-bg-white">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </section>
    </div>
  );
};

export default FilterSearchCard;

