// import React, { useEffect, useState } from "react";
// import Masonry from "react-responsive-masonry";
// import ScrollAnimation from "react-animate-on-scroll";
// // import "animate.css/animate.min.css";

// const GalleryArea = () => {
//   const [galleryItemData, setGalleryItemData] = useState("Show_All");
//   const [animationKey, setAnimationKey] = useState(0);

//   // Define your image data with categories
//   const images = [
//     { src: "6.jpg", category: "restaurant" },
//     { src: "6.jpg", category: "room" },
//     { src: "0.jpg", category: "hotel" },
//     { src: "0.jpg", category: "beach" },
//     { src: "1 (1).jpg", category: "restaurant" },
//     { src: "1 (1).jpg", category: "room" },
//     { src: "1.jpg", category: "beach" },
//     { src: "1.jpg", category: "hotel" },
//     { src: "2 (2).jpg", category: "room" },
//     { src: "2 (2).jpg", category: "restaurant" },
//     { src: "2.jpg", category: "restaurant" },
//     { src: "2.jpg", category: "beach" },
//     { src: "3.jpg", category: "hotel" },
//     { src: "3.jpg", category: "beach" },
//     { src: "3.jpg", category: "room" },
//     { src: "4 (1).jpg", category: "room" },
//     { src: "4 (1).jpg", category: "restaurant" },
//   ];

//   // Function to get unique images based on src
//   const getUniqueImages = (images) => {
//     const seen = new Set();
//     return images.filter((image) => {
//       const isDuplicate = seen.has(image.src);
//       seen.add(image.src);
//       return !isDuplicate;
//     });
//   };

//   // Filter images based on the selected category
//   const filteredImages =
//     galleryItemData === "Show_All"
//       ? getUniqueImages(images)
//       : images.filter((image) => image.category === galleryItemData);

//   // Function to get the appropriate animation class
//   const getAnimationType = (category) => {
//     switch (category) {
//       case "hotel":
//         return "fadeIn";
//       case "restaurant":
//         return "zoomOut";
//       case "beach":
//         return "zoomIn";
//       case "room":
//         return "zoomIn";
//       default:
//         return "fadeIn";
//     }
//   };
//   // Change category and trigger animation
//   const handleCategoryChange = (category) => {
//     setAnimationKey(prevKey => prevKey + 1);
//     setGalleryItemData(category);
//   };
//   useEffect(() => {
//     // Reset animationKey to force re-render when category changes
//     setAnimationKey(prevKey => prevKey + 1);
//   }, [galleryItemData]);

//   return (
//     <section className="gallery-page-area pt-5 rpt-100 pb-5 rpy-100 rel z-1 bg-black">
//       <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
//         <div className="container mx-auto">
//           <div className="flex justify-center pt-24">
//             <div className="">
//               <div className="section-title text-center text-white mb-8 rmb-10">
//                 <h2>Explore Our Gallery</h2>
//               </div>
//             </div>
//           </div>
//           <ul className="w-full gallery-filter filter-btns-one flex justify-center pt-12 pb-12">
//             <li
//               onClick={() => handleCategoryChange("Show_All")}
//               className={`${galleryItemData === "Show_All" ? "current" : ""
//                 } cursor-pointer`}
//             >
//               Show All
//             </li>
//             <li
//               onClick={() => handleCategoryChange("hotel")}
//               className={`${galleryItemData === "hotel" ? "current" : ""
//                 } cursor-pointer`}
//             >
//               Living Room
//             </li>
//             <li
//               onClick={() => handleCategoryChange("restaurant")}
//               className={`${galleryItemData === "restaurant" ? "current" : ""
//                 } cursor-pointer`}
//             >
//               Kitchen
//             </li>
//             <li
//               onClick={() => handleCategoryChange("beach")}
//               className={`${galleryItemData === "beach" ? "current" : ""
//                 } cursor-pointer`}
//             >
//               Bathroom
//             </li>
//             <li
//               onClick={() => handleCategoryChange("room")}
//               className={`${galleryItemData === "room" ? "current" : ""
//                 } cursor-pointer`}
//             >
//               Bedroom
//             </li>
//           </ul>
//           <Masonry columnsCount={3} gutter="1rem">
//             {filteredImages.map((image, index) => (
//               <div key={index} className="p-4">
//                 <ScrollAnimation
//                   key={animationKey + index}
//                   duration={2}
//                   animateIn={getAnimationType(galleryItemData)}
//                   animateOnce={true}
//                   initiallyVisible={true}
//                 >
//                   <div className="gallery-item style-two">
//                     <div className="image">

//                       <img
//                         src={`newImages/images/site/${image.src}`}
//                         alt="Gallery"
//                         className="w-full h-auto object-cover"
//                       />
//                     </div>
//                   </div>
//                 </ScrollAnimation>
//               </div>
//             ))}
//           </Masonry>
//         </div>
//       </ScrollAnimation>
//       <div className="bg-lines">
//         <span></span>
//         <span></span>
//         <span></span>
//         <span></span>
//         <span></span>
//         <span></span>
//         <span></span>
//         <span></span>
//         <span></span>
//         <div className="wave-shapes"></div>
//         <div className="wave-shapes-two"></div>
//       </div>
//     </section>
//   );
// };

// export default GalleryArea;

import React, { useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import { useNavigate } from "react-router-dom";
// import "animate.css/animate.min.css";

const GalleryArea = () => {
  const [galleryItemData, setGalleryItemData] = useState("Living Room");
  const [animationKey, setAnimationKey] = useState(0);
  const [gallaryType, setGallaryType] = useState([]);
  const [gallaryData, setGallaryData] = useState([]);
  const [typeId, setTypeId] = useState(null);
  const navigate = useNavigate();

  // Function to get the appropriate animation class
  const getAnimationType = (category) => {
    switch (category) {
      case "hotel":
        return "fadeIn";
      case "restaurant":
        return "zoomOut";
      case "beach":
        return "zoomIn";
      case "room":
        return "zoomIn";
      default:
        return "fadeIn";
    }
  };
  // Change category and trigger animation
  const handleCategoryChange = (category, id) => {
    setAnimationKey((prevKey) => prevKey + 1);
    setTypeId(id);
    setGalleryItemData(category);
  };

  const getGallaryType = async () => {
    // setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/gallery-type`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setGallaryType(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      // setIsLoading(false);
    }
  };
  const getGallary = async () => {
    // setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/gallery?type=${
          typeId ? typeId : "All"
        }`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setGallaryData(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getGallaryType();
    getGallary();
  }, []);

  useEffect(() => {
    getGallary();
  }, [typeId]);

  return (
    <section className="gallery-page-area pt-5 rpt-100 pb-5 rpy-100 rel z-1 bgc-black">
      <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
        <div className="container mx-auto">
          <div className="flex justify-center pt-24">
            <div className="">
              <div className="section-title text-center text-white mb-8">
                <h2>Explore Our Gallery</h2>
              </div>
            </div>
          </div>
          <ul className="w-full gallery-filter filter-btns-one flex justify-center pt-12 pb-12">
            {gallaryType.map((item, index) => {
              return (
                <>
                  <li
                    key={index}
                    onClick={() => handleCategoryChange(item?.name, item?.id)}
                    className={`${
                      galleryItemData === item?.name ? "current" : ""
                    } cursor-pointer`}
                  >
                    {item?.name}
                  </li>
                </>
              );
            })}
          </ul>

          <Masonry columnsCount={3} gutter="1rem">
            {gallaryData &&
              gallaryData.slice(0, 3).map((images, index) => (
                <div key={index} className="p-4">
                  <ScrollAnimation
                    key={animationKey + index}
                    duration={2}
                    animateIn={getAnimationType(galleryItemData)}
                    animateOnce={true}
                    initiallyVisible={true}
                  >
                    <div className="gallery-item style-two">
                      <div className="image">
                        <img
                          src={images?.image}
                          alt="Gallery"
                          className="w-full object-cover max-h-[213px]"
                          onClick={() => {
                            navigate("/gallery");
                          }}
                        />
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              ))}
          </Masonry>
        </div>
      </ScrollAnimation>
      <div class="bg-lines">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
  );
};

export default GalleryArea;
