import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
// import Header from "./component/comman/header";
import Home from "./component/pages/home/home";
import GallaryList from "./component/pages/gallary/gallaryList";
// import Footer from "./component/pages/footer/footer";
import About from "./component/pages/about us/about";
import BlogList from "./component/pages/blog/blogList";
import BlogDetails from "./component/pages/blog/blogDetails";
// import Aminites from "./component/pages/aminites/aminites";
import AnimitesList from "./component/pages/aminites/aminitesList";
import Property from "./component/pages/property/property";
import PropertyDetails from "./component/pages/property/propertyDetails";
import Login from "./component/pages/login/login";
import SignUp from "./component/pages/Sign Up/signUp";
import ForgotPassword from "./component/pages/forgot password/forgotPassword";
import RecoverPassword from "./component/pages/recover password/recoverPassword";
import ResetPassword from "./component/pages/reset password/resetPassword";
import Booking from "./component/pages/booking/booking";
import Payment from "./component/pages/payment/payment";
import Contact from "./component/pages/contact us/contact";
import EditProfile from "./component/pages/edit profile/editProfile";
import UnitDetails from "./component/pages/Units/unit-details";
import Chat from "./component/pages/chat/chat";
import Apartment from "./component/pages/apartment/apartment";
import FloorPlan from "./component/pages/floorPlans/floorPlan";
import NewHeader from "./component/comman/NewHeader";
import NewHome from "./component/pages/home/NewHome";
import FooterArea from "./component/pages/footer/FooterArea";
import Index from "./component/pages/newUnit/Index";
import { useEffect } from "react";
import LinesAnimation from "../src/component/comman/liners";
import ScrollToTop from "./component/comman/ScrollToTop";
const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();

  // Function to check if the current route is /login or /forgot-password
  const isExcludedRoute = () => {
    return [
      "/login",
      "/forgot-password",
      "/sign-up",
      "/forgot-password",
      "/recover-password",
      "/reset-password",
    ].includes(location.pathname);
  };

  return (
    // <body className="home-one">
    <div>
      <body className="home-one">
        <div className="">
          <div className="preloader" style={{ display: "none" }}></div>
          {!isExcludedRoute() && <NewHeader />}
        </div>
      </body>
      {/* {!isExcludedRoute() && <Header />} */}
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<NewHome />} />
        <Route path="/unit" element={<Index />} />
        <Route path="/home" element={<NewHome />} />
        <Route path="/gallery" element={<GallaryList />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog-Details" element={<BlogDetails />} />
        <Route path="/unit-Details" element={<UnitDetails />} />
        <Route path="/amenities" element={<AnimitesList />} />
        <Route path="/property" element={<Property />} />
        <Route path="/property-details" element={<PropertyDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/apartments" element={<Apartment />} />
        <Route path="/floor-plan" element={<FloorPlan />} />

        {/* <Route path="/chat" element={<NewChat />} /> */}
      </Routes>
      {/* <Chat /> */}
      <LinesAnimation />
      {/* {!isExcludedRoute() && <Footer />} */}
      {!isExcludedRoute() && <FooterArea />}
    </div>
  );
};

export default App;
