import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const NewHeader = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false); // Track sticky state
  const location = useLocation(); // Get current route
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setMobileMenuOpen(false); // Close the mobile menu
  };

  useEffect(() => {
    const handleScroll = () => {
      // Apply sticky class only on the home page when scrollY >= 40
      if (
        location.pathname === "/" ||
        location.pathname === "/contact" ||
        location.pathname === "/blog-Details"
      ) {
        setIsSticky(window.scrollY >= 40);
      }
    };

    // Set initial scroll position
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]); // Run effect when route changes

  const headerClass =
    location.pathname === "/" ||
    location.pathname === "/contact" ||
    location.pathname === "/blog-Details"
      ? `main-header header-white mb-[100px] ${isSticky ? "fixed-header" : ""}`
      : `main-header header-white mb-[100px] fixed-header`; // Always add fixed-header class for non-home pages

  const isMobile = window.innerWidth <= 768;

  return (
    <header className={headerClass}>
      {/* Header-Upper */}
      <div className="header-upper">
        <div className="container container-1720 clearfix mx-auto">
          <div className="header-inner relative flex items-center">
            <div className="logo-outer">
              <div className="logo">
                <NavLink to="/" onClick={handleLinkClick}>
                  <img
                    width={150}
                    src="newImages/images/site/logo.gif"
                    alt="Logo"
                    title="Logo"
                  />
                </NavLink>
              </div>
            </div>
            <div className="nav-outer flex-grow">
              {/* Main Menu */}
              <nav className="main-menu lg:flex lg:items-center lg:space-x-4">
                <div className="navbar-header flex items-center md:hidden">
                  <div className="mobile-logo my-4">
                    <NavLink to="/" onClick={handleLinkClick}>
                      <img
                        src="newImages/images/site/logo.gif"
                        width={100}
                        alt="Logo"
                        title="Logo"
                      />
                    </NavLink>
                  </div>
                  {/* Toggle Button */}
                  <button
                    type="button"
                    className="navbar-toggle flex flex-col space-y-1"
                    onClick={handleToggle}
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div
                  className={`navbar-collapse lg:flex lg:justify-center w-full lg:items-center lg:space-x-4 ${
                    isMobileMenuOpen ? "block" : "hidden"
                  } lg:block`}
                >
                  <ul className="navigation flex flex-col md:flex-row  text-nowrap">
                    <li>
                      <NavLink to="/" onClick={handleLinkClick}>
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/about" onClick={handleLinkClick}>
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/unit" onClick={handleLinkClick}>
                        Availability
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/apartments" onClick={handleLinkClick}>
                        Apartments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/amenities" onClick={handleLinkClick}>
                        Amenities
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/floor-plan" onClick={handleLinkClick}>
                        Virtual Tour
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/gallery" onClick={handleLinkClick}>
                        Gallery
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog" onClick={handleLinkClick}>
                        Blog
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact" onClick={handleLinkClick}>
                        Contact Us
                      </NavLink>
                    </li>
                    <li
                      className="block lg:hidden"
                      style={{ display: isMobile ? "block" : "none" }}
                    >
                      {!token && (
                        <div className="menu-btns lg:hidden mt-2">
                          <NavLink
                            to="/login"
                            className="theme-btn flex items-center !ml-0"
                          >
                            Login Now <FontAwesomeIcon icon={faAngleRight} />
                          </NavLink>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
              {/* Main Menu End */}
            </div>
            {/* Menu Button */}
            {!token ? (
              <div className="menu-btns lg:hidden">
                <NavLink to="/login" className="theme-btn flex items-center">
                  Login Now <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </div>
            ) : (
              <img
                src="/images/profile.jpeg"
                alt="No Images"
                className="header-profile cursor mr-2"
                onClick={() => {
                  navigate("/edit-profile");
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* End Header Upper */}
    </header>
  );
};

export default NewHeader;
