import React, { useState } from "react";
import Virtual from "../../comman/virtualTour";
import { useLocation } from "react-router-dom";
import { ErrorAlert } from "../../comman/sweetalert";
import axios from "axios";
import { useEffect } from "react";

const FloorPlan = () => {
  const location = useLocation();
  const tourId = location?.state?.id;
  const tourUrl = location?.state?.url;
  const [selected, setSelected] = useState(
    tourUrl ||
      "https://s3.amazonaws.com/tours.smalltown360.com/843/88/index.html"
  );
  const [badType, setBadType] = useState([]);

  const [activeIndex, setActiveIndex] = useState(tourId ? tourId : 0);

  const [isLoading, setIsLoading] = useState(true);
  // const handleClick = (name) => {
  //   setSelected(name);
  // };
  const tabsValue = [
    {
      name: "1 BED / 1 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/88/index.html",
    },
    {
      name: "2 BED / 2 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/89/index.html",
    },
    {
      name: "3 BED / 3 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/90/index.html",
    },
    {
      name: "DELUXE 2 BED / HOME OFFICE / 3 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/112/index.html",
    },
    {
      name: "DELUXE 1 BED / HOME OFFICE / 2 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/114/index.html",
    },
  ];
  useEffect(() => {
    getApartment();
  }, []);
  const getApartment = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/appartment`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setActiveIndex(res.data.result[0].id);
      setBadType(res.data.result.map(({ id, title }) => ({ id, title })));
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="comman-header text-center mb-5 mt-[150px] sm:mt-[100px] md:mt-[176px] comman-padding">
            <p>Virtual Tour</p>
          </div>
          <div className="bg-white flex overflow-x-scroll items-center justify-center mt-5 mb-10 comman-padding">
            <ul className=" flex flex-nowrap overflow-x-auto  text-sm font-medium text-center text-gray-500 dark:text-gray-400 ">
              {badType.map((item, index) => (
                <li className="me-2 whitespace-nowrap" key={index}>
                  <div
                    className={`inline-block px-4 py-3 rounded-lg ${
                      activeIndex === index
                        ? "text-white comman-bg rounded-lg active-text"
                        : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor"
                    }`}
                    onClick={() => {
                      setSelected(tabsValue[index].url);
                      setActiveIndex(index);
                    }} // Handle click event
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={selected}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Tour Embed"
            ></iframe>
          </div>
        </>
      )}
    </>
  );
};

export default FloorPlan;
