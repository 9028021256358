import React from "react";
import { motion } from "framer-motion";

const backdropVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, transition: { duration: 1 } },
};

const modalVariants = {
  hidden: (initialState) => ({
    x: initialState.x,
    y: initialState.y,
    width: initialState.width,
    height: initialState.height,
    opacity: 0,
  }),
  visible: {
    x: 0,
    y: 0,
    width: "auto",
    height: "auto",
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
};

const Modal = ({ selectedImage, initialState, setSelectedImage }) => {
  const handleClose = (e) => {
    if (
      e.target.classList.contains("backdrop") ||
      e.target.classList.contains("close-icon")
    ) {
      setSelectedImage(null);
    }
  };

  return (
    <motion.div
      className="backdrop"
      onClick={handleClose}
      variants={backdropVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.img
        src={selectedImage}
        alt="Enlarged Pic"
        className="enlarged-image"
        custom={initialState}
        variants={modalVariants}
      />
      <motion.div className="close-icon" onClick={handleClose}></motion.div>
    </motion.div>
  );
};

export default Modal;
