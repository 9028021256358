import React from 'react'
import HomeSlider from './HomeSlider'
import AboutArea from './AboutArea'
import RoomArea from './RoomArea'
import ServicesArea from './ServicesArea'
import TestimonialsArea from './TestimonialsArea'
import GalleryArea from './GalleryArea'
import BlogArea from './BlogArea'
import InstagramArea from './InstagramArea'
import Chatboat from './Chatboat '
import "animate.css/animate.compat.css"
// import GalleryComponent from './GalleryArea'

const NewHome = () => {
  return (
    <div className="">
        <div class="form-back-drop"></div>
        <HomeSlider />
        <AboutArea />
        <RoomArea />
        <ServicesArea />
        <GalleryArea />
        {/* <GalleryComponent /> */}
        <TestimonialsArea />
        <BlogArea />
        <InstagramArea />
        {/* <Chatboat /> */}
    
      </div>
  )
}

export default NewHome
