import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
const GetAppointment = () => {
  return (
    <div>
      <section className="hidden-bar">
        <div className="inner-box text-center">
          <div className="cross-icon">
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="title">
            <h4>Get Appointment</h4>
          </div>
          {/*Appointment Form*/}
          <div className="appointment-form">
            <form
              method="post"
              action="https://webtendtheme.net/html/2023/qomfort/contact.html"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="text"
                  defaultValue=""
                  placeholder="Name"
                  required=""
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  defaultValue=""
                  placeholder="Email Address"
                  required=""
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="form-group">
                <textarea
                  placeholder="Message"
                  rows={5}
                  defaultValue={""}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="theme-btn bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
                >
                  Submit now
                </button>
              </div>
            </form>
          </div>
          {/*Social Icons*/}
          <div className="social-style-one flex justify-center space-x-4">
            <NavLink to={"/"}
              className="text-blue-400 hover:text-blue-500"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </NavLink>
            <NavLink to={"/"}
              className="text-blue-400 hover:text-blue-500"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </NavLink>
            <NavLink to={"/"}
              className="text-blue-400 hover:text-blue-500"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </NavLink>
            <NavLink to={"/"}
              className="text-blue-400 hover:text-blue-500"
            >
              <FontAwesomeIcon icon={faPinterestP} />
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GetAppointment;
