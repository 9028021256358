import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBicycle,
  faUtensils,
  faSwimmer,
  faCar,
  faSpa,
  faHotTub,
} from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import TruncateTextLimited from "../../comman/trancateLimited";
const ServicesArea = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const navigate = useNavigate();
  const getAmenities = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/amenities`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setAmenities(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAmenities();
  }, []);

  const NavigationPage = () => {
    navigate("/amenities");
  };

  return (
    <div>
      <section className="services-area pt-32 pb-24 relative z-10">
        <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-between items-center mb-8">
              <div className="w-full px-4 sm:w-full md:w-full lg:w-1/2 xl:w-1/3">
                <div className="section-title mb-8 wow fadeInLeft delay-0-2s">
                  <h2>Top Amenities … Same Price!</h2>
                </div>
              </div>
              <div className="w-full px-4 sm:w-full md:w-full lg:w-1/3 Explore_texts">
                <NavLink
                  to={"/amenities"}
                  className="theme-btn style-three mb-8 wow fadeInRight delay-0-2s"
                >
                  Explore More <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </div>
            </div>
            <div className="flex flex-wrap -mx-2">
              {amenities.slice(0, 6).map((item, index) => (
                <div
                  key={index}
                  onClick={() => NavigationPage()}
                  className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4 cursor"
                >
                  <div className="service-item wow fadeInUp delay-0-2s">
                    <div className="icon">
                      <img
                        src={item?.thumbnail}
                        alt="amenities"
                        className=" h-[60px] w-[60px]"
                      />
                    </div>
                    <div className="content mt-3">
                      <h4>{item?.title}</h4>

                      <p className="mt-3">
                        <TruncateTextLimited
                          text={item?.description}
                          maxLength={70}
                        />
                      </p>
                      <NavLink to={"/amenities"} className="read-more mt-3">
                        Read More <FontAwesomeIcon icon={faAngleRight} />
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="service-item wow fadeInUp delay-0-2s">
                  <div className="icon">
                    <FontAwesomeIcon icon={faBicycle} />
                  </div>
                  <div className="content">
                    <h4>
                      <NavLink to={"/amenities"}>Fitness Center</NavLink>
                    </h4>
                    <p>
                      Nam libero tempore, cum soluta nobis est eligende optio
                      cumque nihil impedit quo minus quod
                    </p>
                    <NavLink to={"/amenities"} className="read-more">
                      Read More <FontAwesomeIcon icon={faAngleRight} />
                    </NavLink>
                  </div>
                </div> */}

              {/* <div className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4">
                <div className="service-item wow fadeInUp delay-0-4s">
                  <div className="icon">
                    <FontAwesomeIcon icon={faUtensils} />
                  </div>
                  <div className="content">
                    <h4>
                      <NavLink to={"/amenities"} className="read-more">
                        Food &amp; Restaurants
                      </NavLink>
                    </h4>
                    <p>
                      Nam libero tempore, cum soluta nobis est eligende optio
                      cumque nihil impedit quo minus quod
                    </p>
                    <NavLink to={"/amenities"} className="read-more">
                      Read More <FontAwesomeIcon icon={faAngleRight} />
                    </NavLink>
                  </div>
                </div>
              </div> */}
              {/* <div className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4">
                <div className="service-item wow fadeInUp delay-0-6s">
                  <div className="icon">
                    <FontAwesomeIcon icon={faSwimmer} />
                  </div>
                  <div className="content">
                    <h4>
                      <NavLink to={"/amenities"} className="read-more">
                        Swimming Pool
                      </NavLink>
                    </h4>
                    <p>
                      Nam libero tempore, cum soluta nobis est eligende optio
                      cumque nihil impedit quo minus quod
                    </p>
                    <NavLink to={"/amenities"} className="read-more">
                      Read More <FontAwesomeIcon icon={faAngleRight} />
                    </NavLink>
                  </div>
                </div>
              </div> */}
              {/* <div className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4">
                <div className="service-item wow fadeInUp delay-0-2s">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCar} />
                  </div>
                  <div className="content">
                    <h4>
                      <NavLink to={"/amenities"} className="read-more">
                        Transportation
                      </NavLink>
                    </h4>
                    <p>
                      Nam libero tempore, cum soluta nobis est eligende optio
                      cumque nihil impedit quo minus quod
                    </p>
                    <NavLink to={"/amenities"} className="read-more">
                      Read More <FontAwesomeIcon icon={faAngleRight} />
                    </NavLink>
                  </div>
                </div>
              </div> */}
              {/* <div className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4">
                <div className="service-item wow fadeInUp delay-0-4s">
                  <div className="icon">
                    <FontAwesomeIcon icon={faSpa} />
                  </div>
                  <div className="content">
                    <h4>
                      <NavLink to={"/amenities"} className="read-more">
                        SPA Treatments
                      </NavLink>
                    </h4>
                    <p>
                      Nam libero tempore, cum soluta nobis est eligende optio
                      cumque nihil impedit quo minus quod
                    </p>
                    <NavLink to={"/amenities"} className="read-more">
                      Read More <FontAwesomeIcon icon={faAngleRight} />
                    </NavLink>
                  </div>
                </div>
              </div> */}
              {/* <div className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4">
                <div className="service-item wow fadeInUp delay-0-6s">
                  <div className="icon">
                    <FontAwesomeIcon icon={faHotTub} />
                  </div>
                  <div className="content">
                    <h4>
                      <NavLink to={"/amenities"} className="read-more">
                        Jacuzzi
                      </NavLink>
                    </h4>
                    <p>
                      Nam libero tempore, cum soluta nobis est eligende optio
                      cumque nihil impedit quo minus quod
                    </p>
                    <NavLink to={"/amenities"} className="read-more">
                      Read More <FontAwesomeIcon icon={faAngleRight} />
                    </NavLink>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </ScrollAnimation>
        <div className="bg-lines for-bg-white">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
    </div>
  );
};

export default ServicesArea;
