import React from "react";

const BannerPage = () => {
  return (
    <div>
      <section
        className="page-banner-area pt-140 rpt-80 pb-240 rpb-150 rel z-1 bgs-cover bgc-black text-center"
        style={{ backgroundImage: "url(newImages/images/site/banner.png)" }}
      >
        <div className="container mx-auto">
          <div className="banner-inner text-white rpb-25">
            <h1 className="page-title wow fadeInUp delay-0-2s">Our Units</h1>
          </div>
        </div>
        <div class="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
    </div>
  );
};

export default BannerPage;
