import React from 'react'
import GetAppointment from './GetAppointment'
import BannerPage from './BannerPage'
import SearchFilter from './SearchFilter'
import RoomsGrid from './RoomsGrid'
import Chatboat from './Chatbot'

const Index = () => {
  return (
    <div className='mt-[100px] sm:mt-[100px] md:mt-[150px]'>
      <GetAppointment />
      <BannerPage />
      <SearchFilter />
      <RoomsGrid />
      {/* <Chatboat /> */}
    </div>
  )
}

export default Index
