import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlay } from '@fortawesome/pro-light-svg-icons';
// import { faAngleRight } from '@fortawesome/free-regular-svg-icons';
import {
  faPlay,
  faAngleRight,
  faHotel,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import HomeModal from "./HomeModal";

const AboutArea = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <section className="about-area pt-32 pb-32 rpt-100 rel">
      <div className="container mx-auto comman-padding">
        <div className="flex flex-wrap items-center justify-center">
          <div className="w-full sm:w-full md:w-full lg:w-1/2 mb-14 lg:mb-0">
            <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
              <div className="about-image-part rmb-55">
                <div className="top-part">
                  <img src="newImages/images/site/about1.png" alt="About" />
                  <ScrollAnimation
                    duration={2}
                    animateIn="fadeInLeft"
                    animateOnce={true}
                    className="icon"
                  >
                    <div className="icon wow fadeInLeft delay-0-2s">
                      <FontAwesomeIcon icon={faHotel} />
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="bottom-part">
                  <ScrollAnimation
                    duration={2}
                    animateIn="fadeInRight"
                    animateOnce={true}
                    // className="icon"
                    // className="video-play-text"
                    className="mfp-iframe video-play-text wow  delay-0-2s"
                  >
                    {/* <a
                      href="/video/vid.mp4"
                      // target="_blank"
                      // className="mfp-iframe video-play-text wow  delay-0-2s"
                      className="text-white"
                    > */}
                    <FontAwesomeIcon
                      icon={faPlay}
                      className="mr-2"
                      onClick={() => setShowVideo(true)}
                    />
                    <span>
                      <b>Watch Latest</b>
                      <br />
                      <i>Videos</i>
                    </span>
                    {/* </a> */}
                  </ScrollAnimation>

                  <img src="newImages/images/site/about2.png" alt="About" />
                </div>
              </div>
            </ScrollAnimation>
          </div>

          {/* Content Part */}
          <div className="w-full sm:w-5/6 md:w-5/6 lg:w-1/2">
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp "
              animateOnce={true}
            >
              <div className="about-content-part">
                <div className="section-title mb-8">
                  <span className="sub-title block mb-4">
                    About Grand Monarch
                  </span>
                  <h2 className="text-3xl font-bold">Luxury Boutique Living</h2>
                  <p className="mt-4">
                    Grand Monarch has one main goal, to provide: Luxury Boutique
                    Living! Luxury: we offer the highest end furniture,
                    appliances and more. Boutique: with only 24 units, we are
                    small but pack a punch, offering all the amenities of larger
                    complexes with a nice cozy, homey feel. Living: we offer
                    what matters to you the most, services, safety and
                    flexibility.
                  </p>
                </div>
                <div className="feature-list mt-8">
                  <div className="feature-item flex items-start mb-4">
                    <div className="icon mr-4">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="container mx-auto">
                      <h5 className="text-xl font-semibold">Our Vision</h5>
                      <p className="mt-2 text-sm">
                        Our vision is to provide the highest standards of
                        grandeur living with the ambiance of living in a small
                        boutique complex, all in a location central to
                        everything you need!
                      </p>
                    </div>
                  </div>
                  <div className="feature-item flex items-start mb-4">
                    <div className="icon mr-4">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="container mx-auto">
                      <h5 className="text-xl font-semibold">Our Mission</h5>
                      <p className="mt-2 text-sm">
                        Our mission is to provide a safe, homey living
                        experience for all our tenants, with our focus always on
                        service and responsiveness, to make Grand Monarch your
                        home!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="about-author mt-8">
                  <div className="theme-btn flex items-center text-white">
                    <NavLink
                      to={"/about"}
                      className="theme-btn flex items-center text-white"
                    >
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
          {/* </ScrollAnimation> */}
        </div>
      </div>
      <div class="bg-lines for-bg-white">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <AnimatePresence>
        {showVideo && (
          <HomeModal
            children={
              <div className="">
                <video controls autoPlay>
                  <source
                    // src={require("../../../assets/video/vid.mp4")}
                    src="https://orratech.com/monarch/assets/video/aboutvod.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            }
            // selectedImage={selectedImage}
            // initialState={initialState}
            onClose={() => setShowVideo(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default AboutArea;
