import React from "react";

const Loader = () => {
  return (
    <div className="h-screen flex items-center justify-center main-bg">
      {/* <img src="/images/loader-1.gif" alt="Lodding" /> */}
      <img src="/newImages/images/preloader.gif" alt="Lodding" />
    </div>
  );
};

export default Loader;
