// import React from "react";
// import { motion } from "framer-motion";
// import video from "../../../assets/video/vid.mp4";

// const backdropVariants = {
//   visible: { opacity: 1, transition: { duration: 1 } },
//   hidden: { opacity: 0, transition: { duration: 1 } },
// };

// const modalVariants = {
//   hidden: (initialState) => ({
//     x: initialState.x,
//     y: initialState.y,
//     width: initialState.width,
//     height: initialState.height,
//     opacity: 0,
//   }),
//   visible: {
//     x: 0,
//     y: 0,
//     width: "auto",
//     height: "auto",
//     opacity: 1,
//     transition: {
//       type: "spring",
//       stiffness: 300,
//       damping: 30,
//     },
//   },
// };

// const HomeModal = ({ setSelectedImage }) => {
//   const handleClose = (e) => {
//     if (
//       e.target.classList.contains("backdrop") ||
//       e.target.classList.contains("close-icon")
//     ) {
//       setSelectedImage(null);
//     }
//   };

//   return (
//     <motion.div
//       className="backdrop p-16"
//       onClick={handleClose}
//       variants={backdropVariants}
//       initial="hidden"
//       animate="visible"
//       exit="hidden"
//     >
//       <motion.div className="absolute top-40 right-1/3" onClick={handleClose}>
//         x
//       </motion.div>
//       <video width="40%" height="40%" controls autoPlay>
//         <source
//           src={require("../../../assets/video/vid.mp4")}
//           type="video/mp4"
//         />
//       </video>
//       {/* <video
//         src={selectedImage}
//         alt="Enlarged Pic"
//         className="enlarged-image"

//         // custom={initialState}
//         // variants={modalVariants}
//       >

//       </video> */}
//     </motion.div>
//   );
// };

// export default HomeModal;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const HomeModal = ({ onClose, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose(true);
    }, 300);
  };

  return (
    <div
      className={`fixed inset-0 z-[999] backdrop-blur-sm flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className={`relative bg-white rounded-lg shadow-lg max-w-3xl w-full mx-4 md:mx-0 custom-transform transition-all duration-300  ${
          isVisible ? "modal-enter" : "modal-leave"
        }`}
      >
        <div className="absolute right-0 -top-5">
          <button onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={20}
              width={20}
              viewBox="0 0 384 512"
              fill="white"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
        <div className="md:p-12 p-4 max-h-[80vh]  overflow-hidden flex flex-col">
          {children}
        </div>
      </div>
    </div>
  );
};

export default HomeModal;
