import axios from "axios";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { ErrorAlert, SuccessAlert } from "../../comman/sweetalert";
import bannerImage from "../../../assets/images/banner.png";
const Contact = () => {
  const initialValues = {
    name: "",
    number: "",
    email: "",
    reason: "",
  };
  const contactUsSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    number: Yup.string()
      .required("Mobile Number is Required")
      .min(10, "mobile number must be 10 digits")
      .max(10, "mobile number must not be Greater than 10 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    reason: Yup.string().required("Reason is required"),
  });

  const onSubmitForm = async (values, action) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact-us`,
        values
      );
      if (response.status === 200) {
        const data = response.data;
        SuccessAlert({ title: data.msg });
        action.resetForm();
      }
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
      action.resetForm();
    }
  };
  return (
    <>
      {" "}
      <section
        className="page-banner-area pt-44 pb-48 relative  bg-cover bg-black text-center mb-12"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
        }}
      >
        <div className="container mx-auto">
          <div className="banner-inner text-white">
            <h1 className="page-title text-4xl md:text-5xl lg:text-6xl font-bold animate-fadeInUp delay-200">
              Contact
            </h1>
          </div>
        </div>
        <div className="bg-lines absolute inset-0">
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
          <span className="block w-full h-1 bg-white mb-2"></span>
        </div>
      </section>
      <section className="contact-page-area py-16 relative z-1">
        <div className="container mx-auto">
          <div className="lg:flex justify-between">
            <div className="lg:w-1/2 mb-14 lg:mb-0">
              <div className="our-location-part mb-14 animate-fadeInUp">
                <div className="mb-10">
                  <div className="section-title mb-10">
                    <span className="sub-title mb-4 block text-gray-600">
                      Contact Us
                    </span>
                    <h2 className="text-3xl font-bold">
                      Need Any Consultations to Booked your room
                    </h2>
                  </div>
                </div>

                <div className="tab-content animate-fadeInUp">
                  <div className="tab-pane active">
                    <div className="contact-info-item flex items-center mb-6">
                      <div className="icon text-3xl mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="50"
                          viewBox="0 -960 960 960"
                          width="55px"
                          fill="currentColor"
                        >
                          <path d="M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z" />
                        </svg>
                      </div>
                      <div className="content">
                        <span className="title font-semibold block">
                          Locations
                        </span>
                        <span className="text">
                          <div> 128 Grand Avenue</div>{" "}
                          <div> Johnson City NY 13790</div>
                        </span>
                      </div>
                    </div>
                    <div className="contact-info-item flex items-center mb-6">
                      <div className="icon text-3xl mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="50px"
                          viewBox="0 -960 960 960"
                          width="50px"
                          fill="currentColor"
                        >
                          <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                        </svg>
                      </div>
                      <div className="content">
                        <span className="title font-semibold block">
                          Email Address
                        </span>
                        <span className="text">
                          <a href="mailto:info@grand-monrach.com" className="">
                            info@grand-monrach.com
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="contact-info-item flex items-center">
                      <div className="icon text-3xl mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="50px"
                          viewBox="0 -960 960 960"
                          width="50px"
                          fill="currentColor"
                        >
                          <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                        </svg>
                      </div>
                      <div className="content">
                        <span className="title font-semibold block">
                          Call/Text
                        </span>
                        <span className="text">
                          <a href="tel:+13479153560" className="">
                            +1 (347) 915-3560
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-1/2">
              <div className="contact-page-form animate-fadeInUp">
                <div className="section-title mb-4">
                  <h3 className="text-2xl font-semibold">Send Us Message</h3>
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={contactUsSchema}
                  enableReinitialize
                  onSubmit={async (values, action) =>
                    onSubmitForm(values, action)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                        <div className="col-span-2">
                          <div className="form-group">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control w-full p-2 border border-gray-300 rounded"
                              placeholder="Full name"
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              data-error="Please enter your name"
                            />
                            <div className="text-red-600">
                              {errors.name && touched.name && errors.name}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <input
                              type="number"
                              name="number"
                              // className="input-box mt-1"
                              // placeholder="Phone Number"
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                              // value={values.number}
                              id="phone_number"
                              // name="phone_number"
                              className="form-control w-full p-2 border border-gray-300 rounded"
                              placeholder="Phone"
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.number}
                              data-error="Please enter your Phone"
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="form-control w-full p-2 border border-gray-300 rounded"
                              placeholder="Email"
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              data-error="Please enter your Email"
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="form-group">
                            <textarea
                              name="reason"
                              id="message"
                              className="form-control w-full p-2 border border-gray-300 rounded"
                              rows="3"
                              placeholder="Reason to stay"
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.reason}
                              data-error="Please enter your Message"
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="form-group pt-4 mb-0">
                            <button
                              onClick={handleSubmit}
                              className="theme-btn bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                            >
                              Send Message
                              <i className="far fa-arrow-right ml-2"></i>
                            </button>
                            {/* <div id="msgSubmit" className="hidden"></div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines for-bg-white">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      <div className="contact-page-map py-8 animate-fadeInUp">
        <div className="container-fluid">
          <div className="our-location">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2959.874488692318!2d-75.9536936!3d42.1101588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89daeef969ab6ffb%3A0x11082d4814a2309e!2s128%20Grand%20Ave%2C%20Johnson%20City%2C%20NY%2013790%2C%20USA!5e0!3m2!1sen!2sin!4v1724435663272!5m2!1sen!2sin"
              className="w-full h-96"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Location Map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

// <div className="login-input">
//                         <div className="comman-grey">
//                           <p>Name</p>
//                         </div>
//                         <input
//                           type="text"
//                           name="name"
//                           className="input-box mt-1"
//                           placeholder="Name"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.name}
//                         />
//                       </div>
//                       <div className="text-red-600">
//                         {errors.name && touched.name && errors.name}
//                       </div>

//                       <div className="flex items-center mt-4">
//                         <div className="login-input flex flex-col w-1/2">
//                           <div className="comman-grey">
//                             <p>E-mail</p>
//                           </div>
//                           <input
//                             type="text"
//                             name="email"
//                             className="input-box mt-1"
//                             placeholder="E-mail"
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             value={values.email}
//                           />
//                           <div className="text-red-600">
//                             {errors.email && touched.email && errors.email}
//                           </div>
//                         </div>

//                         <div className="login-input ml-4 flex flex-col w-1/2">
//                           <div className="comman-grey">
//                             <p>Phone Number</p>
//                           </div>
//                           <input
//                             type="number"
//                             name="number"
//                             className="input-box mt-1"
//                             placeholder="Phone Number"
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             value={values.number}
//                           />
//                           <div className="text-red-600">
//                             {errors.number && touched.number && errors.number}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="login-input mt-4">
//                         <div className="comman-grey">
//                           <p>Reason to stay</p>
//                         </div>
//                         <textarea
//                           type="text"
//                           name="reason"
//                           className="text-area mt-1"
//                           placeholder="Write here..."
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.reason}
//                         />
//                       </div>
//                       <div className="text-red-600">
//                         {errors.reason && touched.reason && errors.reason}
//                       </div>

//                       <div className="mt-3">
//                         <button
//                           type="button"
//                           class="text-white  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2
//                    focus:outline-none comman-bg w-full"
//                           onClick={handleSubmit}
//                         >
//                           Submit
//                         </button>
//                       </div>
