import axios from "axios";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../comman/sweetalert";

const Apartment = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState();
  const [apartment, setApartment] = useState([]);
  const [badType, setBadType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getApartment = async (values) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/appartment`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );
      setApartment(res.data.result);
      setActiveIndex(res.data.result[0].id);
      setBadType(res.data.result.map(({ id, title }) => ({ id, title })));
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getApartment();
  }, []);

  const handleItemClick = (id) => {
    setActiveIndex(id);
  };
  const tabsValue = [
    {
      name: "1 BED / 1 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/88/index.html",
    },
    {
      name: "DELUXE 1 BED / HOME OFFICE / 2 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/114/index.html",
    },
    {
      name: "2 BED / 2 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/89/index.html",
    },
    {
      name: "DELUXE 2 BED / HOME OFFICE / 3 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/112/index.html",
    },
    {
      name: "3 BED / 3 BATH",
      url: "https://s3.amazonaws.com/tours.smalltown360.com/843/90/index.html",
    },
  ];
  return (
    <div className="container mx-auto comman-padding">
      <div className="comman-header text-center mb-5 mt-[150px] sm:mt-[100px] md:mt-[176px]">
        <p>Apartments</p>
      </div>

      <div class="flex overflow-x-scroll items-center justify-center">
        <ul class="flex flex-nowrap overflow-x-auto text-sm font-medium text-center text-gray-500 dark:text-gray-400 mt-5">
          {badType.map((item, index) => (
            <li class="me-2 whitespace-nowrap" key={index}>
              <a
                className={`inline-block px-4 py-3 rounded-lg ${
                  activeIndex === item.id
                    ? "!text-white comman-bg rounded-lg"
                    : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white cursor"
                }`}
                onClick={() => handleItemClick(item.id)} // Handle click event
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {apartment &&
        apartment.length > 0 &&
        apartment.map(
          (item, index) =>
            item.id === activeIndex && (
              <div key={index} className="top">
                <Carousel autoPlay infiniteLoop>
                  {item.images.map((img) => {
                    return (
                      <div>
                        <img
                          src={img || "/images/gallery.png"}
                          alt="blogImg"
                          style={{ maxHeight: "500px" }}
                        />
                      </div>
                    );
                  })}
                </Carousel>

                <div className="top p-3">
                  <div
                    className="text-white comman-bg w-fit p-3 cursor-pointer rounded-lg mb-4"
                    onClick={() =>
                      navigate("/floor-plan", {
                        state: { id: item.id, url: tabsValue[index].url },
                      })
                    }
                  >
                    Take Me To Virture Ture
                  </div>
                  <div className=" comman-midium-blue">{item.title}</div>
                  <div
                    className="comman-grey mt-1"
                    style={{ fontSize: "16px", lineHeight: "inherit" }}
                  >
                    {item.description}{" "}
                  </div>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default Apartment;
