import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink } from "react-router-dom";
const HomeSlider = () => {
  return (
    <div>
      <section className="main-slider-area bgc-black-with-lighting rel z-1">
        <div className="main-slider-active  slick-initialized slick-slider slick-dotted ">
          <div className="slider-item draggable slider-item-new">
            <div className="">
              <div className="flex flex-wrap -mt-8 justify-end items-center">
                <div className="w-full px-4 sm:w-full md:w-full lg:w-1/4 xl:w-1/4">
                  <ScrollAnimation
                    duration={3}
                    animateIn="fadeIn"
                    animateOnce={true}
                  >
                    <div className="slider-content">
                      <span className="sub-title">
                        <FontAwesomeIcon icon={faArrowRight} className="mr-2" />{" "}
                        Welcome to Grand Monarch
                      </span>
                      <h1>
                        Luxury <span>Boutique Living</span>
                      </h1>
                      <NavLink to={"/unit"} className="theme-btn">
                        Check Our Availability
                        <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                      </NavLink>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="w-full px-4 sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <div className="slider-image">
                    <video
                      id="videoIframe"
                      width="100%"
                      autoPlay
                      muted
                      loop
                      controls
                    >
                      <source
                        src="https://orratech.com/monarch/assets/video/slider1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-shapes"></div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
    </div>
  );
};

export default HomeSlider;
