import axios from "axios";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ErrorAlert } from "../../comman/sweetalert";
import Loader from "../../comman/loader";

const RoomArea = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [unit, setUnit] = useState([]);
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const getUnits = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/unit-details`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setUnit(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUnits();
  }, []);
  const Navigation = (id) => {
    navigate("/unit-Details", { state: { id: id } });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="room-area-three py-130 rpy-100 rel z-1 bgc-black">
          <ScrollAnimation duration={3} animateIn="fadeIn" animateOnce={true}>
            <div className="container mx-auto">
              <div className="flex justify-center">
                <div className="w-full max-w-xl text-center text-white mb-15">
                  <div className="section-title text-center text-white mb-60 rmb-40 wow fadeInUp delay-0-2s">
                    <h2>Explore Our Unit Details</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mx-auto custom-slider">
              <Slider {...settings} className="room-div">
                {unit &&
                  unit.map((item) => {
                    const ImgUrl = item?.thumbnail[0];
                    return (
                      <div
                        className="room-two-active"
                        onClick={() => Navigation(item?.id)}
                      >
                        <div className="room-two-item wow fadeInUp delay-0-2s">
                          <div className="image">
                            <img
                              src={
                                ImgUrl
                                  ? ImgUrl
                                  : "newImages/images/site/livingroom.png"
                              }
                              className="w-[420px] h-[300px] object-cover rounded-none"
                              alt="Room"
                            />
                          </div>
                          <div className="content">
                            <h3
                              onClick={() => Navigation(item?.id)}
                              className="cursor-pointer"
                            >
                              {item?.title}
                              {/* <NavLink
                                to="/unit-Details"
                                state={{ id: item?.id }}
                              >
                                {item?.title}
                              </NavLink> */}
                            </h3>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </ScrollAnimation>
          <div class="bg-lines">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </section>
      )}
    </>
  );
};

export default RoomArea;
