const LinesAnimation = () => {
  return (
    <div class="bg-lines for-bg-white">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
export default LinesAnimation;
