// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ErrorAlert } from "../../comman/sweetalert";
// import moment from "moment";
// import Loader from "../../comman/loader";
// import { useNavigate } from "react-router-dom";
// import TruncateTextLimited from "../../comman/trancateLimited";

// const BlogList = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [blog, setBlog] = useState([]);
//   const navigate = useNavigate();

//   const getBlogs = async () => {
//     setIsLoading(true);
//     try {
//       const res = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/webapp/blogs`,
//         {
//           headers: {
//             "ngrok-skip-browser-warning": 0,
//           },
//         }
//       );

//       setBlog(res.data.result);
//     } catch (error) {
//       ErrorAlert({ title: error?.response?.data?.error });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const Navigation = (id) => {
//     navigate("/blog-Details", { state: { id: id } });
//   };

//   useEffect(() => {
//     getBlogs();
//   }, []);

//   return (
//     <>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <div className="container mx-auto comman-padding">
//           <div className="grid grid-cols-12 gap-4 top">
//             {blog.map((item, index) => (
//               <div
//                 className="col-span-12 lg:col-span-4 cursor"
//                 key={index}
//                 onClick={() => {
//                   Navigation(item.id);
//                 }}
//               >
//                 <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700">
//                   <a>
//                     <img
//                       class="rounded-t-lg"
//                       src="/images/gallery.png"
//                       alt=""
//                     />
//                   </a>
//                   <div class="p-5">
//                     <a>
//                       <h5 class="mb-2 text-2xl comman-blue-font">
//                         {item?.title}
//                       </h5>
//                     </a>
//                     <p class="mb-3 comman-grey">
//                       <TruncateTextLimited
//                         text={item?.description}
//                         maxLength={70}
//                       />
//                     </p>
//                   </div>

//                   <div className="flex justify-between p-3 mx-2 items-center">
//                     <p className="comman-blue-font">{item?.author}</p>
//                     <p className="comman-grey">
//                       {moment(item.date).format("DD MMMM, YYYY")}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default BlogList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import moment from "moment";
import Loader from "../../comman/loader";
import { useNavigate } from "react-router-dom";
import TruncateTextLimited from "../../comman/trancateLimited";
import "tailwindcss/tailwind.css";
import { motion, AnimatePresence } from "framer-motion";

const BlogList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  const navigate = useNavigate();

  const getBlogs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/blogs`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setBlog(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const Navigation = (id) => {
    navigate("/blog-Details", { state: { id: id } });
  };

  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container mx-auto comman-padding">
          <div className="comman-header text-center mb-2 mt-[150px] sm:mt-[100px] md:mt-[176px] ">
            <p>Blog</p>
          </div>
          <div className="grid grid-cols-12 space-y-10 md:space-y-0 md:gap-20">
            <AnimatePresence>
              {blog.map((item, index) => (
                <motion.div
                  key={item.id}
                  className="col-span-12 lg:col-span-4 cursor flex justify-center"
                  initial={{ opacity: 0, y: 50, scale: 0.95 }} // Initial scale set to 0.95
                  animate={{ opacity: 1, y: 0, scale: 1.05 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: 1, delay: 1 }}
                  layout
                  whileHover={{
                    scale: 1,
                    transition: { duration: 0.3, delay: 0.1 },
                  }}
                  onClick={() => Navigation(item.id)}
                >
                  <div className="bg-white border border-gray-200 rounded-lg shadow-lg  hover:shadow-2xl ">
                    <div className="aspect-[16/9] object-cover rounded-lg overflow-hidden">
                      <img className="p-4" src="/images/gallery.png" alt="" />
                    </div>
                    <div className="p-4">
                      <a>
                        <h5 className="mb-2 text-2xl comman-blue-font">
                          {item?.title}
                        </h5>
                      </a>
                      <p className="mb-3 comman-grey">
                        <TruncateTextLimited
                          text={item?.description}
                          maxLength={70}
                        />
                      </p>
                    </div>
                    <div className="flex justify-between p-4 items-center">
                      <p className="comman-blue-font">{item?.author}</p>
                      <p className="comman-grey">
                        {moment(item.date).format("DD MMMM, YYYY")}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogList;
