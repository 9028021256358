import React from "react";

const NoData = () => {
  return (
    <>
      <div className="flex items-center justify-center">
        <img src="/images/nodata.png" alt="No Data" />
      </div>
    </>
  );
};

export default NoData;
