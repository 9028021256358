import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import moment from "moment";
import Loader from "../../comman/loader";
import TruncateTextLimited from "../../comman/trancateLimited";
import { motion, AnimatePresence } from "framer-motion";
import bannerImage from "../../../assets/images/banner.png";

const BlogDetails = () => {
  const location = useLocation();
  const id = location?.state?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [paragraphs, setParagraphs] = useState([]);
  const [blog, setBlog] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const navigate = useNavigate();
  const getBlogs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/blogs/${id}`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setBlog(res.data.result);
      setParagraphs(
        res.data.result.description
          .split("\n")
          .filter((para) => para.trim() !== "")
          .slice(0, 4)
      );
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllBlogs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/blogs`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setBlogData(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const Navigation = (id) => {
    navigate("/blog-Details", { state: { id: id } });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  useEffect(() => {
    getBlogs();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  // const paragraphs =
  //   blog &&
  //   blog.description &&

  // Log each paragraph
  // console.log("paragraphs", paragraphs[2]);
  // paragraphs.forEach((para, index) => {
  //   console.log(`Paragraph ${index + 1}: ${para}`);
  // });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section
            className="page-banner-area relative pt-36 pb-36 bg-cover bg-center bg-black text-center text-white z-10"
            style={{
              backgroundImage: `url(${bannerImage})`,
              backgroundSize: `cover`,
              backgroundRepeat: `no-repeat`,
            }}
          >
            <div className="container mx-auto">
              <div className="mt-12">
                <h2 className="text-4xl font-bold animate-fadeInUp">
                  Here's the latest at Grand Monarch!
                </h2>
                <ul className="flex justify-center space-x-4 mt-5 animate-fadeInUp text-gray-300">
                  <li className="flex items-center space-x-1">
                    <i className="far fa-user"></i>
                    <a href="#" className="hover:underline">
                      Admin
                    </a>
                  </li>
                  <li className="flex items-center space-x-1">
                    <i className="far fa-calendar-alt"></i>
                    <a href="#" className="hover:underline">
                      25 June 2023
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="absolute inset-0 flex justify-center items-center opacity-10">
              <span className="w-full h-px bg-white"></span>
              <span className="w-full h-px bg-white"></span>
              <span className="w-full h-px bg-white"></span>
              <span className="w-full h-px bg-white"></span>
              <span className="w-full h-px bg-white"></span>
              <span className="w-full h-px bg-white"></span>
              <span className="w-full h-px bg-white"></span>
            </div>
          </section>

          <section className="py-10 relative z-20">
            <div className="container mx-auto">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-2/3 px-4">
                  <div className="bg-white p-6 shadow-lg rounded-lg">
                    <h2 className="text-3xl font-bold mb-4 animate-fadeInUp">
                      {blog.title}
                    </h2>
                    <div className="flex flex-wrap -mx-4 mb-6">
                      <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0">
                        <img
                          src={
                            blog.images &&
                            blog.images.length > 0 &&
                            blog.images[1]
                          }
                          alt="Blog Single"
                          className="w-full h-auto rounded-full object-cover"
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-4">
                        <p className="text-lg mb-4 first-letter:text-3xl first-letter:font-bold">
                          {paragraphs.length > 0 && paragraphs[1]}
                        </p>
                      </div>
                    </div>
                    <p className="mb-6">
                      {paragraphs.length > 1 && paragraphs[2]}
                    </p>
                    <div className="flex flex-wrap -mx-4 mb-6">
                      <div className="w-full md:w-1/2 px-4">
                        <p className="mb-4">
                          {paragraphs.length > 2 && paragraphs[3]}
                        </p>
                      </div>
                      <div className="w-full md:w-1/2 px-4">
                        <img
                          src={
                            blog.images &&
                            blog.images.length > 1 &&
                            blog.images[2]
                          }
                          alt="Blog Single"
                          className="w-full h-auto rounded-full object-cover"
                        />
                      </div>
                    </div>
                    <p className="mb-6">
                      {paragraphs.length > 3 && paragraphs[4]}
                    </p>
                    <div className="border-l-4 border-gray-300 pl-4 italic mb-6">
                      {/* {blog.} */}
                      <span className="block text-sm text-gray-500">
                        {blog.author}
                      </span>
                    </div>
                    {paragraphs.length > 3 &&
                      paragraphs.slice(3).map((para, index) => (
                        <div key={index} className="mb-4">
                          <p className="text-gray-700">{para}</p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4 mt-10 lg:mt-0">
                  <div className="space-y-6">
                    <div className="bg-white p-6 shadow-lg rounded-lg">
                      <h4 className="text-2xl font-semibold mb-4">
                        Recent Blog
                      </h4>
                      <ul className="space-y-4">
                        {blogData &&
                          blogData.length > 0 &&
                          blogData.map((item, i) => (
                            <li
                              key={i}
                              className="border border-gray-300 p-4 rounded-lg shadow-sm text-center"
                            >
                              <img
                                src={item.images.length > 0 && item.images[1]}
                                alt="blog"
                                className="w-full mb-4 rounded-lg"
                              />
                              <h5 className="text-xl mb-2">{item.title}</h5>
                              <div
                                onClick={() =>
                                  navigate("/blog-Details", {
                                    state: { id: id },
                                  })
                                }
                                className="text-blue-500 hover:underline cursor-pointer"
                              >
                                Read More <i className="far fa-angle-right"></i>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {/* <div className="bg-white p-6 shadow-lg rounded-lg">
                      <h4 className="text-2xl font-semibold mb-4">Gallery</h4>
                      {[...Array(3)].map((_, rowIndex) => (
                        <div
                          key={rowIndex}
                          className="flex flex-wrap -mx-2 mb-4"
                        >
                          {[...Array(3)].map((_, colIndex) => (
                            <div key={colIndex} className="w-1/3 px-2">
                              <a href="#">
                                <img
                                  src={`assets/images/site/${colIndex}.jpg`}
                                  alt="Gallery"
                                  className="w-full h-auto rounded-lg"
                                />
                              </a>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-white opacity-10 z-[-1] pointer-events-none">
              <span className="block w-full h-full bg-gradient-to-r from-white to-gray-100"></span>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default BlogDetails;
