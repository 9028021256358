import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorAlert } from "../../comman/sweetalert";
import { Modal } from "react-responsive-modal";
import Loader from "../../comman/loader";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { useSpring, animated } from "react-spring";
import "react-responsive-modal/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion } from "framer-motion";

const AnimitesList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [amenities, setAmenities] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentAmenity, setCurrentAmenity] = useState(null);
  const getAmenities = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/webapp/amenities`,
        {
          headers: {
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setAmenities(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenModal = (amenity) => {
    setCurrentAmenity(amenity);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    getAmenities();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <animated.div
          // style={animationProps}
          className="container mx-auto comman-padding"
        >
          <div className="comman-header text-center mb-5 mt-[150px] sm:mt-[100px] md:mt-[176px]">
            <p>Amenities</p>
          </div>
          <div className="grid grid-cols-12 space-y-7 md:space-y-0 md:gap-14 top comman-padding">
            {amenities.map((item, index) => (
              <motion.div
                className="col-span-12 lg:col-span-6 flex justify-center border rounded-lg shadow-lg max-w-screen-sm"
                key={index}
                initial={{ opacity: 0, y: 50, scale: 0.95 }} // Initial scale set to 0.95
                animate={{ opacity: 1, y: 0, scale: 1.05 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: index * 0.1 }}
                layout
                whileHover={{
                  scale: 1,
                  transition: { duration: 0.3, delay: 0.1 },
                }} // Scale up to 1.05 on hover
              >
                <div className="flex w-full">
                  <div className="w-full aspect-[16/9] object-fill">
                    <img
                      src={item.image}
                      alt="No Amenities"
                      className="rounded-l-lg aspect-[16/9]"
                    />
                  </div>
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="p-1 text-center flex flex-col justify-between">
                      <div>
                        <p className="font-semibold text-base">{item?.title}</p>
                      </div>
                      <div>
                        <button
                          className="text-white font-medium rounded-lg text-sm px-5 py-2.5 mt-2 focus:outline-none comman-bg"
                          onClick={() => onOpenModal(item)}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="">
            <Modal open={open} onClose={onCloseModal} center>
              {currentAmenity && (
                <div className="p-4 ">
                  <div className="aspect-[16/9]">
                    <Carousel autoPlay infiniteLoop showThumbs={false}>
                      {currentAmenity?.image?.map((items, index) => (
                        <div key={index}>
                          <img
                            src={items}
                            alt=""
                            className="w-full  h-[550px]"
                          />
                        </div>
                      ))}
                    </Carousel>
                    <p className="comman-grey mt-2">
                      {currentAmenity.description}
                    </p>
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default AnimitesList;
//  <div className="col-span-12 lg:col-span-4" key={index}>
//                 <animated.div className="amenities-box transform scale-110 transition-transform duration-500 hover:scale-100">
//                   <div className=" flex items-center justify-center">
//                     <img
//                       src={item.image}
//                       alt="No Image"
//                       className="rounded-l-lg w-full aspect-[16/9] object-cover "
//                     />
//                   </div>
//                   <div className="aminites-card p-3 mt-3 text-center">
//                     <p className="font-bold text-lg">{item?.title}</p>
//                     <button
//                       className="text-white font-medium rounded-lg text-sm px-5 py-2.5 mt-2
//                         focus:outline-none comman-bg"
//                       onClick={() => onOpenModal(item)}
//                     >
//                       Read More
//                     </button>
//                   </div>
//                 </animated.div>
//               </div>
